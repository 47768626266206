import React from "react";
import Layout from "../components/Layout";
import WhereToBuyUs from "../components/WhereToBuyUs/wheretobuyus";
import watermark from "../img/leafWaterMark.png";
import "../scss/wheretobuy.scss";
import Helmet from "react-helmet";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";

const WhereToBuy = (props) => {
    const { t } = useTranslation();

    return (
        <Layout title={t("whereToBuyPage.metatitle")} description={t("whereToBuyPage.metadescription")}>
            <Helmet> 
                <link rel="alternate" href="https://www.firstpridethailand.com/wheretobuy/" hreflang="th-th" />
                <link rel="alternate" href="https://www.firstpride.sg/wheretobuy" hreflang="en-sg" />
            </Helmet>
                <>
                    <div className="wheretobuy">
                        <div className="wheretobuyBanner">
                            <div className="wheretobuyBannerContent">
                                <h1>{t("whereToBuyPage.title1")}</h1>
                                <h5>{t("whereToBuyPage.subtitle1")}</h5>
                            </div>
                        </div>
                        <div className="wheretobuyComponent">
                            <div>
                                <WhereToBuyUs heading={false} />
                            </div>
                        </div>
                    </div>
                    <div className="wheretobuyFooterBanner"></div>
                </>
        </Layout>
    );
};

export default (WhereToBuy);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "navbar", "footer", "whereToBuy"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;